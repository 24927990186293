/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;350;400&family=Quicksand&display=swap"); */
*,
#root {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  color: #000;
  overflow-x: hidden;
  background-color: #f3f3f3;
  -webkit-font-smoothing: antialiased;
  background-image: url("assets/images/blob-scene-hcs-bkgd.svg");
  background-position: center center;
  background-size: cover;
  width: auto;
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
section{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
}
/* section{
  height: 5vh;
} */
img {
  width: 100%;
}
svg {
  width: 50px;
  height: 50px;
  color: #E32E2C;
  fill: currentColor;
}

.header{
  z-index: 1;
  min-height: 10vh;
  width: 100%;
  line-height: 50px;
  font-family: sans-serif;
  background-color: #023047;
}
.main{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 85vh;
  /* display: flex;
  flex-direction: column; */
}
/* .hero{
  border-bottom: #fb8500 solid 10px;
} */
.hcs-logo-name {
  font-size: 16px;
  line-height: 15px;
  text-align: justify;
  color: #fb8500;
  font-weight: 300;
  text-shadow: 0 0 10px rgb(20 48 71);
}
.spacer {
  display: block;
}
/* hr{
  width:100px; 
  margin: auto;
  height: 1px;
  background-color: #fb8500;
  border:none;
} */
.navbar{
  padding-top: .1rem;
  padding-bottom: .1rem;
}
.nav-link{
  color : #fff;
  text-decoration: none;
}
.nav-link:hover{
  color : #FB8500;
  font-weight: 700;
  text-decoration: none;
}
.hcs-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-list' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/%3e%3c/svg%3e");
}
.hcs-navbar.navbar-toggler {
  border-color: #FB8500;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.hcs-btn{
  /* color : #FB8500; */
  padding: 0.5em 1em;
  font-weight: bold;
  border-color: #fb8500;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  -moz-transition: background 0.3s linear;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.hcs-btn:hover {
  background-color: #fb8500;
  color: #fff;
  -moz-transition: background 0.3s linear;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
/* footer {
  display: none;
  text-align: center;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  width: 100%;
} */
footer{
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
}
.homepage {
  background-image: url("assets/images/banner2.jpg");
  background-position: center center;
  background-size: cover;
  width: auto;
  left: 0;
  min-height: 85vh;
  min-width: 100%;
  position: absolute;
}
.providers-list-title{
  margin-top:1%;
  text-align: center;
}

/* Homepage header */
.providers-list {
  color: #ffffff;
  font-size: 56px;
  font-weight: 300;
  position: relative;
  text-align: center;
  text-shadow: 0 0 10px rgb(0 0 0 / 33%);
  margin-top: 150px;
  z-index: 3;
}
.motto {
  font-size: 30px;
  color: #ffffff;
  text-shadow: 0 0px 5px rgb(0 0 0 / 33%);
  text-align: center;
  margin-top: 50px;
}
.motto-subtext {
  font-size: 20px;
  color: #ffffff;
  text-shadow: 0 0 10px black !important;
  text-align: center;
  margin-top: 50px;
}
/* dropdown */
.providers-county-dropdown {
  text-align: center;
}
.hcs-dropdown-btn {
  color: #fff;
  background-color: #fb8500;
  border-color: #fb8500;
}
.hcs-dropdown-btn:hover {
  color: #fb8500;
  background-color: #fff;
  border-color: #fb8500;
}
.btn-check:active + .hcs-dropdown-btn,
.btn-check:checked + .hcs-dropdown-btn,
.hcs-dropdown-btn.active,
.hcs-dropdown-btn:active,
.show > .hcs-dropdown-btn.dropdown-toggle {
  color: #fb8500;
  background-color: #eee5e9;
  border-color: #fb8500;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #fb8500;
  font-size: 18px;
  -webkit-box-shadow: 0px 5px 10px 0px #023047; 
  box-shadow: 0px 5px 10px 0px #023047;
}
.btn-check:focus+.btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #fb8500;
  border-color: #fb8500;
  box-shadow: 0 0 0 0.25rem #fb8500;
}
.dropdown-header {
  background-color: #fb8500;
  color: #fff;
  font-size: 18px;
  -webkit-box-shadow: 0px 5px 10px 0px #023047; 
  box-shadow: 0px 5px 10px 0px #023047;
}
.dropdown-menu {
  min-width: 11rem;
  width: 15rem;
}
/* CARD */
/* .card--header{
  border-top: #fb8500 solid 1px;
} */
.card--header > hr{
    margin: 0.5rem 0;
}
.card--title {
  color: #023047;
  font-size: 20px;
  margin-top: 1.5%;
  font-weight: 700;
}
.card--contract-no{
  font-size: 15px;
  margin-top: 2%;
}
.contract-no{
  letter-spacing: 8px;
}
.card--info > p {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.card--info > p > svg{
  fill: #fb8500;
  width: 15px;
  height: 15px;
}

.hcs-providers-page > p >svg{
  fill: #fb8500;
  width: 16px;
  height: 16px;
}

.hcs-providers-counties > p >svg{
  fill: #fb8500;
  width: 16px;
  height: 16px;
}
.cards{
  /* background-color: #F0F0F5; */
  max-width: 1200px;
  padding: 1rem;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));    
}
.provider-card{
  border-top: #fb8500 solid 1px;
}
.card, .provider-card{
  padding: 0 1rem;
  font-size: 16px;
  background-color: white;
  border-radius: 10px;
  border-bottom-right-radius: 15%;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
  opacity: 0.9;
}
.card-list{
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

#cards-container {
  border-top: #fb8500 solid 1px;
  padding: 1% 2% 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 33%);
}

/* .card{
  min-width: 300px;
  border-bottom: 10px solid #fb8500; comment this out to remove the border
  box-shadow: 5px 5px 15px 1px rgba(251,133,0,1) !important;
  -webkit-box-shadow: 5px 5px 15px 1px rgba(251,133,0,1) !important;
  -moz-box-shadow: 5px 5px 15px 1px rgba(251,133,0,1) !important;
} */
/* .card:hover {
  transform: scale(1.1);
}
.card:hover .card-body {
  display: block;
  -webkit-line-clamp: none;
  -webkit-box-orient: none;
  overflow: visible;
} */
.card-header {
  color:#fb8500;
  text-transform: uppercase;
  height: auto;
  text-align: left;
  padding-left:3%;
  padding-top: 1rem;
  background-color: #023047;
}
/* .card-header {
  color: #f5f5f5;
  text-transform: uppercase;
  padding: .5rem 1rem;
  background-color: #023047;
} */
.hcs-providers-card-name{
  font-size:18px;
  font-weight: 600;
}
.hcs-providers-card-contract-no{
  font-size:14px;
  font-weight:500;
}

.hcs-providers-card-label{
  font-weight: 700;
}
.hcs-providers-card-text{
  font-size: 1rem;
  font-weight: 400;
  text-shadow: #FB8500;
}

.providers-othercounties{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}
.providers-othercounties:hover{
  color: #FB8500;
  font-weight: 700;
  transition: 0.5s;
  -moz-transition: background 0.5s linear;
  -webkit-transition: background  0.5s linear;
  transition: background  0.5s linear;
  margin-bottom: 0.5rem !important;
}
.card-header > a {
  color: #eee5e9;
  text-decoration: none;
  line-height: 1;
}
.card-body {
  font-size: 14px;
  min-height: 60vh;
}
.card-footer{
  padding:0;
  border-bottom-right-radius:50px !important;
  background: rgba(255, 255, 255, 0.5);
}
.card-footer>a{
  color: #023047;
  font-size: 18px;
  font-weight: 500;
  padding:3% 0;
  text-decoration: none;
  line-height: 1;
  display: block;
}
.card-footer>a:hover{
  color: #FB8500;
  font-weight: 700;
  transition: 0.5s;
  -moz-transition: background 0.5s linear;
  -webkit-transition: background  0.5s linear;
  transition: background  0.5s linear;
}
.hcs-providers-page{
  font-size: 1rem;
  /* min-height: 60vh; */
  padding:3%;
  background-color: #F7FAFC;
}
.hcs-ad-section{
  position: fixed;
}
/* search input */
.hcs-filter-search{
  width: 100%;
}
.provider-search-filter{
  padding: 0 3%;
}
.hcs-search{
  padding: 0 10%;
}
.search-input {
  outline: 0;
  height: 42px;
  width:300px;
  line-height: 42px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 1px solid #023047;
  float: left;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 7px 7px 7px 7px;
  box-shadow :0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%);
}
.search-input:focus {
  border: 1px solid #FB8500;
}
.provider-pagesize{
  align-items: center;
}
/* About us */
.hcs-about-text{
  font-size: 1.125rem;
}
/* pagination */
.providers-summary-pagination{
  padding-top: 1%;
}
.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .page-item:hover {
  color:#fff;
  background-color: #fb8500;
  cursor: pointer;
}
@media (min-width: 600px) {
    .cards { grid-template-columns: repeat(2, 1fr); }
  }
@media (min-width: 900px) {
    .cards { grid-template-columns: repeat(3, 1fr); }
}
/* Responsive: Portrait tablets and up */
@media screen and (max-width: 760px) {
  .body-content {
    padding: 0;
  }
  .providers-list {
    color: #ffffff;
    font-size: 35px;
    font-weight: 300;
    position: relative;
    text-align: center;
    text-shadow: 0 0 10px rgb(0 0 0 / 33%);
    margin-top: 50px;
    z-index: 3;
  }
  #cards-container{
    padding: 1% 5%;
  }
  /* .card-body, .card-footer{
    display: none;
  } */
  .hcs-providers-card-name {
    font-size: 12px;
    font-weight: initial;
  }
  .nav-item{
    font-size: 1.5rem;
    text-align: center;
  }
  .hcs-ad-section{
    position:initial;
  }
  .search-input {
    width:94vw;
  }
  .provider-search-filter{
    padding: 3%;
  }
}
